<script>
import { mapGetters } from 'vuex'

import PageSubmenu from '@/components/PageSubmenu.vue'

export default {
  components: { PageSubmenu },
  computed: {
    ...mapGetters(['domain']),
    links () {
      const array = [
        {
          label: 'Types',
          path: '/app/questions/types',
          roles: ['admin', 'community-manager']
        },
        {
          label: 'Catégories',
          path: '/app/questions/categories',
          roles: ['admin', 'director', 'community-manager']
        },
        this.domain?.slug !== 'nosdroits' && {
          label: 'Média droit social',
          path: '/app/questions/universals',
          roles: ['admin', 'director', 'community-manager']
        },
        {
          label: 'FAQ',
          path: '/app/questions',
          roles: ['admin', 'director', 'community-manager']
        }
      ]
      return array
    }
  },
  created () {

  }
}
</script>

<template>
  <PageSubmenu :links="links" />
</template>
