<template>
  <div>
    <PageHeader
      title="FAQ > Types"
      description="Gestion des types de la FAQ"
      :button="{
        icon: 'fas fa-plus-circle',
        text: `Créer un type`,
        action: create
      }"
    />
    <QuestionsSubmenu />

    <PageContent>
      <section class="list">
        <b-table
          :data="types"
          v-if="domain"
          striped
          paginated
        >

          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="fas fa-frown"
                    size="is-large">
                  </b-icon>
                </p>
                <p>Aucune catégorie.</p>
              </div>
            </section>
          </template>

          <b-table-column field="label" label="Nom" v-slot="props">
            <b-input
                v-if="props.row.editing"
                type="text"
                v-model="props.row.name"
                minLength="5"
                @blur="updateType(props.row)"
                @keyup.enter.native="updateType(props.row)">
            </b-input>
            <div v-else>
              {{ props.row.name }}
            </div>
          </b-table-column>
          <b-table-column field="actions" label="Actions" v-slot="props">
            <a @click="edit(props.row)">
              <b-icon icon="fas fa-pencil"></b-icon>
            </a>
          </b-table-column>
        </b-table>
      </section>
    </PageContent>
</div>

</template>

<script>
import { http } from '@/services/api.service'
import CreateType from './Modals/Create'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'
import QuestionsSubmenu from '../QuestionsSubmenu.vue'

export default {
  name: 'app-question-types-list',
  components: { PageHeader, PageContent, QuestionsSubmenu },
  computed: {
    domain () {
      this.getTypes()
      return this.$store.getters.domain
    }
  },
  data () {
    return {
      types: []
    }
  },
  created () {
    this.getTypes()
  },
  methods: {
    goToType (type) {
      this.$router.push({ path: `/app/questions/types/${type.uuid}` })
    },
    edit (el) {
      this.types = this.types.map(type => {
        type.editing = false
        if (type.uuid === el.uuid) {
          type.editing = true
          type.originalValue = type.name
        }
        return type
      })
    },
    updateType (type) {
      const name = type.name
      http.put(`/faq/type/${type.uuid}`, { name }).then(res => {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Le type a été mise à jour avec succès !',
          position: 'is-bottom-left',
          type: 'is-success',
          hasIcon: true
        })
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à mettre à jour ce type',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
        type.name = type.originalValue
      }).finally(() => {
        this.types = this.types.map(type => {
          type.editing = false
          return type
        })
      })
    },
    getTypes () {
      http.get('/faq/type').then(res => {
        this.types = res.data
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à récuperer la liste des types',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    create (type) {
      this.$buefy.modal.open({
        parent: this,
        component: CreateType,
        hasModalCard: true,
        width: 800,
        destroyOnHide: false,
        ariaRole: 'dialog',
        ariaModal: true,
        events: {
          created: (created) => {
            this.types.push(created)
            this.$buefy.notification.open({
              duration: 4000,
              message: 'Le type a été créée avec succès !',
              position: 'is-bottom-left',
              type: 'is-success',
              hasIcon: true
            })
          }
        }
      })
    }
  }
}
</script>
